import { useHistory, useLocation } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import _ from 'lodash'
import orderAppLib from 'dimorder-orderapp-lib'

import { Capacitor } from '@capacitor/core'
import { actions, useDispatch, useSelector } from '@/redux'
import { calculateDisplayBatchTotal, isSetItemComplete } from '@/libs/order'
import { createApplePaySession } from '@/libs/payments'
import { getBatchItemsTotal, useBatchItems, useRewardItems } from '@/hooks/useCart'
import { useAddress, useIsLogin } from '@/hooks/user'
import { useDeliveryType, useLoadings, useSystem, useTable } from '@/hooks/app'
import { useIsDimbox } from '@/hooks/dimbox'
import { useMenuQuery } from '@/hooks/queries/menu/useMenuQuery'
import { useMerchant } from '@/hooks/merchant'
import { useSelectedBatch, useSelectedOrder, useShipping } from '@/hooks/order'
import Merchant from '@/constants/merchant'
import constants from '@/constants'
import useD2CBaseUrl from '@/hooks/useD2CBaseUrl'

import FooterBase from '@/components/Footers/FooterBase'

const { TABLE, TAKEAWAY, STORE_DELIVERY } = constants.deliveryType
const { ERRORS } = constants.app
const ERROR_PREFIX = 'app.component.footer.checkout.error.'

/**
 *
 * @param {*} props
 * @returns
 */
export default function CheckoutFooter (props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const d2cBaseUrl = useD2CBaseUrl()
  const system = useSystem()
  const { deliveryType, isStoreDelivery, isTable } = useDeliveryType()
  const isDimbox = useIsDimbox()
  const table = useTable()
  const address = useAddress()
  const isLogin = useIsLogin()
  const merchant = useMerchant()
  const payFirst = useSelector(state => state.merchant?.data?.setting?.payFirst)
  const merchantId = useSelector(state => state.merchant?.data?.id)
  const selectedOrder = useSelectedOrder()
  const selectedBatch = useSelectedBatch()
  const batches = [selectedBatch]
  const selectedPaymentMethod = useSelector(state => state.payment.selectedPaymentMethod)
  const isPromoCodeLoading = useSelector(state => state.order.isApplyPromoCodeLoading || state.order.isAutoFillPromoCodeLoading || state.order.isDeletePromoCodeLoading)
  const shippingFee = useShipping()?.totalFee
  const canShip = _.isNumber(shippingFee)
  const displayBatchTotal = calculateDisplayBatchTotal(selectedBatch, selectedOrder) // 未送出的餐點金額，使用本地 selectedBatch 計算
  const loadings = useLoadings()
  const availableDimboxCount = useSelector(state => state.dimbox.available)
  const rewardItems = useRewardItems()
  const { hasAlcoholDisclaimerItem } = useBatchItems()
  const menuQuery = useMenuQuery()
  const sets = menuQuery.data?.sets

  const isCalculateOrderLoading = useSelector(state => state.order.isCalculateOrderLoading)
  const isCalculateOrderError = useSelector(state => state.order.isCalculateOrderError)
  const disableSubmitByCalculate = isCalculateOrderLoading || isCalculateOrderError // 訂單正在計算或是計算中出現錯誤，禁用 submit
  const isLoading = menuQuery.isLoading || loadings.createShipping || loadings.submit || disableSubmitByCalculate || isPromoCodeLoading

  const quantity = useMemo(() => getBatchItemsTotal(batches).quantity, [batches])

  const onSubmitOrder = () => {
    // create apple pay session 必須在 onClick function 的 callstack 中呼叫，不可以放到 async function 中
    createApplePaySession(selectedOrder, selectedPaymentMethod);

    (async () => {
      const isMerchantAllowCashPayFirst = [Merchant.明輝茶餐廳].includes(merchantId)
      if (!isLogin && !(!Capacitor.isNativePlatform() && isTable)) {
        // 未登入，先打開登入 drawer (但是在 web 中且是內狀態時不用打開登入 Drawer)
        dispatch(actions.app.toggleLoginDrawer(true))
        return
      }

      const isNeedAddress = isStoreDelivery
      if (isNeedAddress && (
        (address.id === 'CURRENT' && !address.isComplete) ||
        _.isEmpty(address)
      )) {
        // 地址使用當前地址，且未經過編輯頁面設定，讓他去編輯頁面確認要不要填樓層等詳細資訊
        // （代表這個地址為未登入用戶臨時設定的地址）
        dispatch(actions.user.updateExitAddressEditBackLevel(1))
        dispatch(actions.user.addAddressTemp(address))
        history.push('/settings/address/edit')
      } else if ([TAKEAWAY, STORE_DELIVERY].includes(deliveryType) && !selectedPaymentMethod) {
        // 外送自取一定要選付款方式
        dispatch(actions.app.toggleDrawer('paymentMethod', true))
      } else if (isTable && !selectedPaymentMethod && payFirst && !isMerchantAllowCashPayFirst) {
        // 堂食先付款一定要選付款方式
        dispatch(actions.app.toggleDrawer('paymentMethod', true))
      } else if (hasAlcoholDisclaimerItem) {
        // 購物車中有東西且 categoryTag 不同，需要詢問並清除才能繼續切換
        dispatch(actions.app.toggleAlert({
          title: t('app.component.alert.alcohol_disclaimer.title'),
          message: t('app.component.alert.alcohol_disclaimer.message'),
          buttons: [
            {
              text: t('app.component.alert.alcohol_disclaimer.cancel'),
              onClick: () => {},
            },
            {
              text: t('app.component.alert.alcohol_disclaimer.confirm'),
              onClick: () => dispatch(actions.order.submitOrderBatch()),
              style: { backgroundColor: theme.palette.primary.main },
            },
          ],
        }))
      } else {
        dispatch(actions.order.submitOrderBatch())
      }
    })()
  }

  const totalPrice = useMemo(() => {
    switch (deliveryType) {
      case STORE_DELIVERY:
      case TAKEAWAY:
        return selectedOrder?.roundedTotalWithRiceCoinDiscount
      case TABLE:
        return pathname.includes('/pay') || payFirst
          ? selectedOrder?.roundedTotalWithRiceCoinDiscount ?? selectedOrder?.roundedTotal // pay 頁面要看整張 order 總共多少錢，內用會沒有 roundedTotalWithRiceCoinDiscount
          : orderAppLib.libs.getDisplayRoundNumber(displayBatchTotal.total, merchant.rounding) // 其他時候看當下加點的 batch 多少錢
      default:
        break
    }
  }, [selectedOrder, displayBatchTotal.total])
  let meetMinCharge = true
  if (isTable && merchant?.setting?.enableDineInCustomerPay) {
    const minCharge = merchant?.setting?.dineInCreditCardPaymentMinCharge
    meetMinCharge = totalPrice >= minCharge
  }

  const uncompletedSetNames = useMemo(() => {
    // 找出未完成的套餐
    const names = []
    selectedBatch?.items.forEach(item => {
      const setMenu = sets?.[item.menuId]
      if (setMenu) {
        const isCompleted = isSetItemComplete(item, setMenu)
        if (!isCompleted) { names.push(item.name) }
      }
    })
    return names
  }, [selectedBatch?.items, menuQuery.data])

  /**
   * @returns {IFooterSubmitButtonProps}
   */
  const createSubmitButtonProps = () => {
    const { enable: systemEnable } = system[deliveryType]
    const errors = []

    if (!systemEnable) {
      errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.SYSTEM_DISABLED))
    }

    if (isLoading) {
      errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.LOADING))
    }

    if (disableSubmitByCalculate) {
      errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.LOADING_CALCULATION))
    }

    if (uncompletedSetNames.length > 0) {
      errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.UNCOMPLETED_SET, { setName: uncompletedSetNames[0] }))
    }

    switch (deliveryType) {
      case STORE_DELIVERY:
        if (!_.isEmpty(address) && !canShip) {
          errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.CANT_SHIP))
        }

        // TODO: handle isEmpty(address)?
        return {
          title: t('app.component.footer.checkout.submit_delivery_order.title'),
          subtitle: t('app.component.footer.checkout.submit_delivery_order.subtitle'),
          onClick: onSubmitOrder,
          disabled: errors.length > 0,
          errors,
        }
      case TAKEAWAY:
        if (isDimbox) {
          if (quantity > availableDimboxCount) {
            errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.DIMBOX_NOT_ENOUGH_BOXES))
          }

          return {
            title: t('app.component.footer.checkout.submit_takeaway_order.title'),
            subtitle: 'DimBox',
            onClick: onSubmitOrder,
            disabled: errors.length > 0,
            errors,
          }
        }

        return {
          title: t('app.component.footer.checkout.submit_takeaway_order.title'),
          subtitle: t('app.component.footer.checkout.submit_takeaway_order.subtitle'),
          onClick: onSubmitOrder,
          disabled: errors.length > 0,
          errors,
        }
      case TABLE:
        if (!table) {
          // 沒有桌號，提示掃 QRCode
          return {
            title: t('app.component.footer.checkout.scan_qr.title'),
            subtitle: t('app.component.footer.checkout.scan_qr.subtitle'),
            onClick: () => {
              dispatch(actions.app.openQRCodeScanner())
            },
            style: { minWidth: 120, textAlign: 'left' },
            disabled: errors.length > 0,
            errors,
            hideQuantityLabel: true, // 掃碼入座時的按鈕不需顯示 QuantityLabel
          }
        } else if (pathname.includes('/pay')) {
          // /pay 後付款

          // 沒有 batch 時禁止付款
          const hasBatch = selectedOrder?.batches?.length > 0
          if (!hasBatch) {
            errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.NO_BATCH))
          }

          // 有未確認的 batch 時禁止付款
          const unconfirmedBatch = selectedOrder?.batches?.find(batch => batch.status !== 'confirmed')
          if (unconfirmedBatch) {
            errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.UNCONFIRMED_BATCH))
          }

          if (!meetMinCharge) {
            errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.AMOUNT_TOO_SMALL))
          }

          return {
            title: t('app.component.footer.checkout.pay.title'),
            onClick: () => {
              if (!selectedPaymentMethod) {
                return dispatch(actions.app.toggleDrawer('paymentMethod', true))
              }

              // create apple pay session 必須在 onClick function 的 callstack 中呼叫，不可以放到 async function 中
              createApplePaySession(selectedOrder, selectedPaymentMethod);

              (async () => {
                const hasOfflineDevice = await dispatch(actions.merchant.findOfflineDevice(merchant?.id, true)) // 內用時檢查 merchant 是否離線
                if (hasOfflineDevice) {
                  dispatch(actions.app.toggleMaterDeviceOfflineAlert(hasOfflineDevice))
                  return
                }

                history.replace(`${d2cBaseUrl}/order_tracking`, { resetOrder: true }) // 前往 OrderTracking 並結束點餐
                await dispatch(actions.payment.payOrder(selectedOrder)) // 付款
              })()
            },

            disabled: errors.length > 0,
            errors,
            hideQuantityLabel: true, // 後付款時的按鈕不需顯示 QuantityLabel
          }
        } else {
          if (payFirst && !meetMinCharge) {
            errors.push(t(ERROR_PREFIX + ERRORS.SUBMIT_BTN_DISABLED.AMOUNT_TOO_SMALL))
          }

          return {
            title: t('app.component.footer.checkout.submit_table_order.title'),
            subtitle: t('app.component.footer.checkout.submit_table_order.subtitle'),
            onClick: onSubmitOrder,
            disabled: errors.length > 0,
            errors,
          }
        }
      default:
        break
    }
  }
  const submitButtonProps = createSubmitButtonProps()

  return (
    <FooterBase
      quantity={displayBatchTotal.quantity + rewardItems.total.quantity}
      total={isPromoCodeLoading ? '--' : totalPrice}
      submitButtonProps={{
        ...submitButtonProps,
        isLoading,
      }}
    />
  )
}
