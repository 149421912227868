import { Capacitor } from '@capacitor/core'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import fixPrecision from 'dimorder-orderapp-lib/dist/libs/fixPrecision'
import moment from 'moment'
import produce from 'immer'

import { actions } from '@/redux'
import { calculateRiceCoinDiscount } from '@/libs/riceCoin'
import { checkIsDimbox } from '@/libs/dimbox'
import { findDistrict, getLalamoveQuotation } from '@/libs/shipping/lalamove'
import { get3DSUrlFor2c2p } from '@/libs/paymentMethod'
import { getCurrentOpening } from '@/libs/dateTimePicker'
import { isExpired, isNotBegin, validateMinOrderAmount, validatePromoCodes, validateQuota } from '@/libs/promoCode'
import { isPoonChoiCategoryTag } from '@/libs/poonchoi'
import DeliveryType from '@/constants/deliveryType'
import Merchant from '@/constants/merchant'
import config from '@/config'
import constants from '@/constants'
import delay from '@/libs/delay'
import dimboxCutOfTime from '@/constants/dimboxCutOfTime'
import dimorderApi from '@/libs/api/dimorder'
import handleAxiosError from '@/libs/handleAxiosError'
import history from '@/libs/history'
import i18n from '@/i18n'
import libs from '@/libs'
import logger, { flush, logId } from '@/libs/logger'
import messageType from '@/constants/message'

/** @typedef {import('dimorder-orderapp-lib/dist/types/AppOrder').IAppOrder} IAppOrder */
/** @typedef {import('dimorder-orderapp-lib/dist/types/PromoCode').IPromoCode} IPromoCode */
/** @typedef {import('dimorder-orderapp-lib/dist/types/PromoCode').IPromoCodes} IPromoCodes */
/** @typedef {import('dimorder-orderapp-lib/dist/types/Merchant').IShipping} IShipping */
/** @typedef {import('dimorder-orderapp-lib/dist/types/Order').ISubmitBatchParams} ISubmitBatchParams */
/** @typedef {import('@/redux/order/OrderState.d').IRiceCoinDiscount} IRiceCoinDiscount */

import { initBatch, initOrder } from './reducer'
import ActionTypes from './ActionTypes'

const { TABLE, TAKEAWAY, STORE_DELIVERY, SHOP } = constants.deliveryType

async function getImmediateTime (merchantId, date = moment().format('YYYY-MM-DD'), deliveryType, shippingTime) {
  // 跟 api 拿最新的即時時間
  const data = await dimorderApi.timeslot.getTimes({ date, shippingTime, deliveryType, merchantId, forAll: false })
  const immediateTime = _.find(data, timeslot => timeslot.isImmediate)?.time
  const immediate = moment(`${date} ${immediateTime}`)
  return immediate.isValid() ? immediate : undefined
}

async function checkTimeAvailable (merchantId, pickupAt, deliveryType, shippingTime) {
  const data = await dimorderApi.timeslot.checkAvailable({ shippingTime, deliveryType, merchantId, pickupAt: pickupAt.toISOString() })
  return data.result ?? false
}

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch, getState) => {
    const params = getState().app.params
    const selectedMerchant = getState().merchant

    if (!params?.merchantId && !selectedMerchant) return

    let newOrder = null

    if (params.orderId) {
      // 動態QR： QRcode 掃到的是帶 orderID 的 URL
      logger.log(`[order.init] params has orderId: ${params.orderId}, dispatch order.joinOrder`, { params })
      try {
        newOrder = await dimorderApi.order.joinOrder(params.orderId)
        if (!newOrder) {
          dispatch(actions.app.resetTable()) // 從 params 拿掉不能加入的 orderId 和 table
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.join_order.not active order.title'),
            message: i18n.t('app.component.alert.join_order.not active order.message'),
          }))
        }
      } catch (error) {
        handleAxiosError(error, {
          responseErrorMessagePath: 'response.data.error',
          loggerPrefix: '[order.init] joinOrder',
          loggerExtraData: { orderId: params.orderId },
          responseErrorHandler: (errorMessage) => {
            dispatch(actions.app.resetTable()) // 從 params 拿掉不能加入的 orderId 和 table
            if (errorMessage === 'not active order') {
              dispatch(actions.app.toggleAlert({
                title: i18n.t('app.component.alert.join_order.not active order.title'),
                message: i18n.t('app.component.alert.join_order.not active order.message'),
              }))
            } else {
              dispatch(actions.app.toggleAlert({
                title: i18n.t('app.component.alert.join_order.default.title'),
                message: i18n.t('app.component.alert.join_order.default.message'),
              }))
            }
          },
        })
      }
    } else if (params.table) {
      // 掃枱角： QRcode 掃到的是帶 table 的 URL
      logger.log(`[order.init] params has table: ${params.table}, dispatch order.createOrder`, { params })
      try {
        newOrder = await dispatch(actions.order.createOrder({ ...params }))
        if (!newOrder) {
          dispatch(actions.app.resetTable()) // 從 params 拿掉不能加入的 orderId 和 table
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.join_order.default.title'),
            message: i18n.t('app.component.alert.join_order.default.message'),
          }))
        }
      } catch (error) {
        dispatch(actions.app.resetTable()) // 從 params 拿掉不能加入的 orderId 和 table
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.join_order.default.title'),
          message: i18n.t('app.component.alert.join_order.default.message'),
        }))
      }
    }

    // 如果有從 params 找到 table 或 order 的話就在這裡還原 order 並開始點餐
    if (newOrder) {
      const selectedMerchantId = getState().merchant?.data?.id
      const selectedBatch = getState().orderBatch.selectedBatch

      dispatch(actions.app.updateTable(newOrder.table))
      dispatch(actions.order.selectOrder(newOrder))
      dispatch(actions.orderHistory.selectOrder(newOrder.id)) // 如果有成功加入內用桌號或內用單的話則需要把歷史訂單設為新的內用單
      if (selectedMerchantId === params.merchantId && selectedBatch) {
        // 如果原本已經有 batch，且是同一間餐廳，將 batch 還原
        dispatch(actions.orderBatch.restoreBatch(selectedBatch))
      }
      dispatch(actions.order.startOrder())

      if (params.isD2CWeb && newOrder && localStorage.getItem('FROM_IN_APP_QRCODE_SCAN')) {
        // 餐廳模式且有桌號或訂單號，且是從 QRCode 掃描進入，是從餐廳餐廳首頁掃描進來的，直接進入 /menu 點餐
        console.log('[order.init] push to /menu')
        history.pushWithSearch(`/d2c/${params.merchantId}/menu`)
        localStorage.removeItem('FROM_IN_APP_QRCODE_SCAN')
      }
    } else {
      // 建立新的 order
      dispatch(actions.order.createLocalOrder())
    }
  }
}

/**
 * 登入後使用，加入正在點餐的訂單
 * @returns {ThunkFunction}
 */
export function joinOrder () {
  return async (dispatch, getState) => {
    const selectedOrder = getState().order.selectedOrder
    // 有 serial 代表訂單已經到 submit 到後端了
    if (selectedOrder?.serial) {
      try {
        const order = await dimorderApi.order.joinOrder(selectedOrder.id)
        dispatch(actions.order.selectOrder(order))
        dispatch(actions.orderHistory.updateOrder(order))
      } catch (error) {
        handleAxiosError(error, {
          responseErrorMessagePath: 'response.data.error',
          loggerPrefix: '[joinOrder] joinOrder',
          loggerExtraData: { orderId: selectedOrder.id },
          responseErrorHandler: (errorMessage) => {
            if (errorMessage === 'not active order') {
              dispatch(actions.app.resetOrderId())
              dispatch(actions.app.toggleAlert({
                title: i18n.t('app.component.alert.join_order.not active order.title'),
                message: i18n.t('app.component.alert.join_order.not active order.message'),
              }))
            } else {
              dispatch(actions.app.toggleAlert({
                title: i18n.t('app.component.alert.join_order.default.title'),
                message: i18n.t('app.component.alert.join_order.default.message'),
              }))
            }
          },
        })
      }
    }
  }
}

/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function selectOrderById (orderId) {
  return async (dispatch, getState) => {
    const orders = getState().orderHistory.orders
    const order = orders.find(order => order.id === orderId)
    dispatch(selectOrder(order))
  }
}

/**
 * @param {IAppOrder} targetOrder
 * @returns {ThunkFunction}
 */
export function selectOrder (targetOrder) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_ORDER,
      payload: { order: targetOrder },
    })
  }
}

/**
 * 不給 path 整個 selectedOrder 用 value 蓋過去
 * @param {PropertyPath} path
 * @param {any} value
 * @returns {ThunkFunction}
 */
export function updateSelectedOrder (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SELECTED_ORDER,
      payload: { path, value },
    })
  }
}

/**
 * @param {Partial<IAppOrder>} updateFields
 * @returns {ThunkFunction}
 */
export function updateSelectedOrderFields (updateFields) {
  return async (dispatch, getState) => {
    const surcharge = getState().merchant.data.surcharge
    const selectedOrder = getState().order.selectedOrder
    if (selectedOrder.orderSerial) {
      console.log('已送出的訂單無法直接修改')
      return
    }
    const updatedOrder = {
      ...selectedOrder,
      ...updateFields,
    }

    if (updatedOrder.deliveryType === TABLE) {
      updatedOrder.surcharge = surcharge
    } else {
      updatedOrder.surcharge = { percent: 0, amount: 0 }
    }

    dispatch(selectOrder(updatedOrder))
  }
}

/**
 * @param {string?} orderId 有給 id 會從 orderHistory 中選擇
 * @param {string?} batchId 如果要指定 batch 可以給 batchId
 * @returns {ThunkFunction}
 */
export function startOrder (orderId, batchId) {
  return (dispatch, getState) => {
    const batchStorageString = localStorage.getItem('batchStorage')
    let batchStorage = null
    try {
      batchStorage = batchStorageString && JSON.parse(batchStorageString)
    } catch (error) {
      console.log('parse batchStorage error', error)
      localStorage.removeItem('batchStorage')
    }

    const selectedOrder = getState().order.selectedOrder
    const selectedBatch = getState().orderBatch.selectedBatch

    if (orderId) {
      // 如果有給 orderId 從 orderHistory.orders 找出 order 來用
      const orders = getState().orderHistory.orders
      const order = orders.find(order => order.id === orderId)
      if (order) {
        dispatch(selectOrder(order))
      }
    }

    if (selectedBatch?.orderId === selectedOrder.id) {
      // 選擇的訂單沒有變
      const sendedOrderBatch = selectedOrder.batches.find(batch => batch.id === selectedBatch.batchId)
      if (!sendedOrderBatch && !selectedBatch.updatedAt) {
        // 選擇的 batch 還沒送出，可以直接繼續點餐
        return
      }
    }

    if (batchId) {
      const batch = selectedOrder.batches.find(batch => batch.id === batchId)
      if (!batch.updatedAt) {
        // batch 未送出，可以選擇 batch 繼續點餐
        dispatch(actions.orderBatch.selectBatch(batch))
        return
      }
    }

    const restoreItems = batchStorage?.merchantId === selectedOrder?.merchantId ? batchStorage?.batch?.items || [] : []

    // 選擇的 order 變了或是沒選 batch 或是選擇的 batch 已送出時
    // 新開一個 batch 來點餐
    const newBatchId = uuid()
    dispatch(actions.orderBatch.selectBatch({
      ...initBatch,
      id: newBatchId,
      orderId: selectedOrder.id,
      orderSerial: selectedOrder?.serial,
      batchId: newBatchId,
      items: restoreItems,
      index: selectedOrder.batches.length,
      table: selectedOrder.table,
      status: 'pending',
      createdAt: new Date(),
    }))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function createLocalOrder () {
  return (dispatch, getState) => {
    const merchantId = getState().merchant.data.id
    const params = getState().app.params

    const surcharge = getState().merchant.data.surcharge
    const orderId = params.orderId || uuid()
    const order = {
      ...initOrder,
      id: orderId,
      // serial: orderId.split('-').shift(), // 暫時用 orderId 開頭來當 serial
      merchantId: merchantId,
      // submit order 的時候再補上  app.params.deliveryType 和 app.params.table
      // deliveryType: params.deliveryType,
      // table: params.table || undefined,
      needTableware: false,
      surcharge,
    }
    logger.log('[createLocalOrder]', order)
    dispatch(actions.app.updateTable(''))
    dispatch({ type: ActionTypes.DELETE_PROMO_CODE }) // 直接刪除本地 promocode
    dispatch(resetRiceCoinDiscount())
    dispatch(selectOrder(order))
    dispatch(startOrder())
  }
}

/**
 * @param {Partial<IAppOrder>} localOrder
 * @returns {ThunkFunction}
 */
export function createOrder (localOrder) {
  return async (dispatch, getState) => {
    const params = getState().app.params
    const isPoonchoi = isPoonChoiCategoryTag(params.categoryTag)
    const shipping = getState().order.shipping

    const tags = []
    if (isPoonchoi) { tags.push({ name: 'poonchoi' }) } // 是盆菜的話要帶 poonchoi tag

    const orderData = {
      from: Capacitor.isNativePlatform() ? 'CUSTOMER_APP' : 'CUSTOMER_WEB',
      merchantID: localOrder?.merchantId,
      orderID: params.orderId || localOrder?.id,
      deliveryType: params.deliveryType || localOrder?.deliveryType,
      table: params.table || localOrder?.table,
      adults: localOrder?.adults,
      children: localOrder?.children,
      customerCount: 1,
      tags: !_.isEmpty(tags) ? tags : undefined,
    }

    if (orderData.deliveryType !== TABLE) {
      if (!params.datetime.date || !params.datetime.time) {
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.submit_order_failed.title'),
          messages: i18n.t('app.component.alert.submit_order_failed.error_message.pickup_time_empty'),
        }))
        return
      }
      orderData.pickupAt = moment(`${params.datetime.date} ${params.datetime.time}`, 'YYYY-MM-DD HH:mm').format()
    }

    if (orderData.deliveryType === STORE_DELIVERY) {
      orderData.shippingTime = shipping?.shippingTime ?? 0
      orderData.scheduleAt = shipping?.scheduleAt
    }

    try {
      const createdOrder = await dimorderApi.order.createOrder(orderData)
      logger.log(`[createOrder] create order ${orderData.orderID}`, { orderToCreate: orderData, createdOrder: createdOrder })
      return createdOrder
    } catch (error) {
      console.log('[createOrder] error', error)

      handleAxiosError(error, {
        responseErrorMessagePath: 'response.data.error',
        loggerPrefix: '[createOrder] order create',
        loggerExtraData: { orderData },
        responseErrorHandler: (errorMessage) => {
          const defaultTitleKey = 'app.component.alert.axios.unknow_error.title'
          const defaultMessageKey = 'app.component.alert.axios.unknow_error.message'
          const titleKey = `app.component.alert.create_order.${errorMessage}.title`
          const messageKey = `app.component.alert.create_order.${errorMessage}.message`

          const title = i18n.exists(titleKey) ? i18n.t(titleKey) : i18n.t(defaultTitleKey)
          const message = i18n.exists(messageKey) ? i18n.t(messageKey) : i18n.t(defaultMessageKey)

          switch (errorMessage) {
            case 'no such merchant':
              dispatch(actions.app.toggleAlert({
                title,
                message,
                button: {
                  text: i18n.t('app.common.back'),
                  onClick: async () => {
                    // 將 gcp log 剩下還沒送出的 log 都送出才 reload
                    await flush()
                    // 重新載入至餐廳列表
                    document.location.href = '/restaurants'
                  },
                },
              }))
              break
            case 'no such table':
              dispatch(actions.app.toggleAlert({
                title,
                message,
                button: {
                  text: i18n.t('app.common.back'),
                  onClick: async () => {
                    // 將 gcp log 剩下還沒送出的 log 都送出才 reload
                    await flush()
                    if (params.isD2CWeb) {
                      // 重新載入至餐廳首頁
                      document.location.href = `/d2c/${params.merchantId}`
                    } else {
                      // 重新載入至餐廳列表
                      document.location.href = '/restaurants'
                    }
                  },
                },
              }))
              break
            case 'only for qrcode':
              dispatch(actions.app.toggleAlert({
                title,
                message,
                button: {
                  text: i18n.t('app.common.back'),
                  onClick: async () => {
                    // 將 gcp log 剩下還沒送出的 log 都送出才 reload
                    await flush()
                    if (params.isD2CWeb) {
                      // 重新載入至餐廳首頁
                      document.location.href = `/d2c/${params.merchantId}`
                    } else {
                      // 重新載入至餐廳列表
                      document.location.href = '/restaurants'
                    }
                  },
                },
              }))
              break
            case 'poonchoi pickup_at not available':
              dispatch(actions.app.toggleAlert({
                title,
                message,
              }))
              break
            case 'time not available for takeaway':
              dispatch(actions.app.toggleAlert({
                title,
                message,
              }))
              break
            default:
              if (errorMessage.startsWith('pickup_at or schedule_at not available')) {
                // 錯誤訊息為 pickup_at or schedule_at not available, msg: %s
                // 因為 %s 不一定是什麼，所以無法在 case 裡面處理
                const msg = error.response.data?.error.split(', msg: ')?.[1]
                const errorCode = msg ? ` (${msg})` : ''

                // 非營業時間
                dispatch(actions.app.toggleAlert({
                  title: i18n.t('app.component.alert.invalid_pickup_at.title'),
                  message: i18n.t('app.component.alert.invalid_pickup_at.message') + errorCode,
                }))
                return
              }

              // 不明的錯誤，後端的回應沒有 data.error
              dispatch(actions.app.toggleAlert({
                title,
                message: message + ` (${logId})`,
              }))
          }
        },
      })
    }
  }
}
// local flag to prevent rapid submit
let submitting = false
/**
 * @param {boolean} ignoreUnavailable
 * @returns {ThunkFunction}
 */
export function submitOrderBatch (ignoreUnavailable = false) {
  return async (dispatch, getState) => {
    if (submitting) {return}
    dispatch(actions.app.updateLoading('submit', true))
    submitting = true

    /**
     * 結束 submitOrderBatch 前記得要 call 這個 function cleanup
     * @param {IAppOrder?} order
     */
    const endSubmitOrderBatch = (order) => {
      try {
        submitting = false
        dispatch(actions.app.updateLoading('submit', false))
        window.postMessage({
          type: messageType.APPLE_PAY_ORDER_CALLBACK_EVENT,
          payload: { order },
        }, '*')
      } catch (error) {
        logger.error('[submitOrderBatch] endSubmitOrderBatch window.postMessage', { error })
      }
    }

    // * 準備訂單內容

    logger.log('[submitOrderBatch] function begin.')

    const errorMessages = []

    const selectedOrder = getState().order.selectedOrder
    const selectedBatch = getState().orderBatch.selectedBatch
    const rewardItems = getState().orderBatch.rewardItems
    const shipping = getState().order.shipping
    const promoCode = getState().order.promoCode
    const applyRiceCoinDiscount = getState().order.applyRiceCoinDiscount
    const donateRiceCoinReward = getState().order.donateRiceCoinReward
    const usedRiceCoin = getState().order.riceCoinDiscount?.usedRiceCoin
    const usedRiceCoinAmount = getState().order.riceCoinDiscount?.discountAmount
    const params = getState().app.params
    const deliveryType = getState().app.params.deliveryType
    const merchantId = getState().merchant.data.id
    const restaurant = getState().landing.restaurant
    const { payFirst } = getState().merchant.data.setting // 內用先付款
    const system = getState().app.system
    const testDate = getState().app.test.date
    const testTime = getState().app.test.time
    const isFoodAngelEnabled = system.campaign.foodAngel
    const isPoonchoi = isPoonChoiCategoryTag(params.categoryTag)
    const isDimbox = checkIsDimbox(system.dimbox.enable, system.takeaway.enable, _.some(restaurant?.tags, tag => tag?.toLowerCase() === 'dimbox'), deliveryType)
    const selectedPaymentMethod = getState().payment.selectedPaymentMethod
    const d2cBaseUrl = params.isD2CWeb ? `/d2c/${merchantId}` : ''
    const memberId = getState().user.member.id

    logger.log('[submitOrderBatch] get state from redux done.')

    const isFirstBatch = !(selectedOrder?.batches?.length > 0)
    const hasRewardItem = rewardItems.length > 0
    const hasBatchItem = selectedBatch?.items?.length > 0

    // * 開始檢查錯誤
    // 第一個 batch 不允許僅有 reward 卻沒有 batchItem 的情況
    if (isFirstBatch && hasRewardItem && !hasBatchItem) {
      errorMessages.push(i18n.t('app.component.alert.submit_order_failed.error_message.items_not_enough'))
    }

    // 內用時檢查 merchant 是否離線
    if (deliveryType === TABLE) {
      const hasOfflineDevice = await dispatch(actions.merchant.findOfflineDevice(merchantId, true))
      if (hasOfflineDevice) {
        errorMessages.push(i18n.t('app.component.alert.merchant_device_offline.message'))
      }
    }

    // 外送外帶需檢查低消，內用不檢查低消
    if (deliveryType === STORE_DELIVERY) {
      // 考慮 minAmount，另因部分付款最少需支付 10 元，因此不含 ricecoin 折扣需 > 10 元
      const ricecoinModify = applyRiceCoinDiscount ? Math.abs(usedRiceCoinAmount) : 0
      if (selectedOrder.total + ricecoinModify < Math.max(shipping.minAmount, 10)) {
        errorMessages.push(i18n.t('app.component.alert.submit_order_failed.error_message.minimum_spend_not_meet', { min: Math.max(shipping.minAmount, 10) }))
      }
    }

    // 需要至少 10
    if (deliveryType === TAKEAWAY) {
      if (selectedOrder.total < 10) {
        errorMessages.push(i18n.t('app.component.alert.submit_order_failed.error_message.minimum_spend_not_meet', { min: 10 }))
      }
    }

    // 外帶外送檢查時間、支付方式、takeawayInfo
    if (deliveryType !== TABLE) {
      // 未選擇 pickup time
      if (!params.datetime.date || !params.datetime.time) {
        errorMessages.push(i18n.t('app.component.alert.submit_order_failed.error_message.pickup_time_empty'))
      }

      // 未選擇支付方式
      if (!selectedPaymentMethod) {
        errorMessages.push(i18n.t('app.component.alert.submit_order_failed.error_message.payment_method_empty'))
      }
    }

    logger.log('[submitOrderBatch] check error done.')
    // * 檢查錯誤完成
    if (errorMessages.length > 0) {
      logger.error('[submitOrderBatch] with error.', errorMessages)
      endSubmitOrderBatch()
      // 送出失敗
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.alert.submit_order_failed.title'),
        messages: errorMessages,
      }))
      dispatch(actions.orderBatch.updateShowNotCompleteSets(true))
      return
    }

    // * 檢查是否需要 create order
    if (!selectedOrder.orderSerial) {
      // 先 create order
      const createdOrder = await dispatch(createOrder(selectedOrder))
      if (!createdOrder) {
        // create order 失敗，不繼續 submit
        endSubmitOrderBatch()
        return
      }
      // FIXME: TT-1682, in some flow, selectedOrder does not have the full order.
      // 會導致fiserv用錯了auth作為transaction type
      // 暫時只補deliveryType
      selectedOrder.deliveryType = createdOrder.deliveryType
    }

    // * 取得付款資訊 payment 和外送資訊 takeawayInfo
    let payment = null
    let takeawayInfo = null
    if (deliveryType !== TABLE || payFirst) {
      try {
        // 只處理需要 payment 的外帶、外送、堂食先付款
        // 處理第三方支付前相關請求和資料準備
        payment = await dispatch(actions.payment.createPaymentOrder(
          selectedOrder,
          { loggerPrefix: '[submitOrderBatch] createPaymentOrder' },
        ))
      } catch (error) {
        endSubmitOrderBatch()
        return
      }
      try {
        // * 取得運送資訊 takeawayInfo, 需在 createPaymentOrder 之後，以免取得 stale outTradeNo
        takeawayInfo = await dispatch(createTakeawayInfo())

        const isMerchantAllowCashPayFirst = [Merchant.明輝茶餐廳].includes(merchantId)
        if (isMerchantAllowCashPayFirst && payFirst) {
          // TODO: remove this 目前這家餐廳 payFirst 可以不帶 payment submit，其他還是要檢查 payment.source
        } else {
          // 支付方式不有效
          if (!payment?.source) {
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.submit_order_failed.title'),
              message: i18n.t('app.component.alert.submit_order_failed.error_message.payment_method_invalid'),
            }))
          }
        }
      } catch (error) {
        if (error?.message === 'SHIPPING_EXPIRED') {
          // 運費報價過期（超過10分鐘），停止 Submit 並重新取得運費報價
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.shipping_expired.title'),
            message: i18n.t('app.component.alert.shipping_expired.message'),
            dialogProps: { disableBackdropClick: true },
            button: {
              text: i18n.t('app.common.confirm'),
              onClick: () => {
                dispatch(actions.app.updateLoading('backdrop', false))
                dispatch(createShipping())
              },
            },
          }))
        } else if (error?.message === 'NO_AVAILABLE_IMMEDIATE_TIME') {
          // 原本的 pickupAt 不可用且沒有可用的 immediate time，跳出 preorder drawer
          dispatch(actions.app.updateLoading('backdrop', false))
          dispatch(actions.app.openPreorderDrawer(merchantId))
        } else if (error?.message === 'UNABLE_TO_DELIVER') {
          // shippingTime NO_RESULT，表是無法外送到這個地址
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.submit_order_failed.title'),
            message: i18n.t('app.component.footer.content.unable_to_deliver'),
          }))
        } else {
          // 網路問題或沒有 resopnse 預設的顯示請稍後再試
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.axios.network_error.title'),
            message: i18n.t('app.component.alert.axios.network_error.message') + ` (${logId})`,
          }))
        }
        endSubmitOrderBatch()
        return
      }
    }

    // * 最後檢查時間
    // 因為前面檢查時間不允許使用的話會 getImmediateTime 但 getImmediateTime 也有可能拿到不能用的時間
    // 所以最後檢查一次如果還是不能用代表無法下單
    if (deliveryType !== TABLE && !isPoonchoi) {
      const isTimeAvailable = await checkTimeAvailable(merchantId, takeawayInfo.pickupAt, deliveryType, takeawayInfo.shippingTime)
      console.log('final check available', isTimeAvailable)
      if (!isTimeAvailable) {
        // 非營業時間
        dispatch(showInvalidPickupAtAlert())
        endSubmitOrderBatch()
        return
      }
    }

    // * 準備 submit request params
    const tags = []
    if (isPoonchoi) { tags.push({ name: 'poonchoi' }) } // 是盆菜的話要帶 poonchoi tag
    if (isDimbox) { tags.push({ name: 'dimbox' }) } // 是 Dimbox 的話要帶 dimbox tag

    // 兌換贈品，格式參考 CA-1667
    const rewards = rewardItems.map(rewardItem => ({
      rewardId: rewardItem.id,
      quantity: rewardItem.quantity,
    }))

    /** @type {ISubmitBatchParams} */
    const submitBatchParams = {
      preSubmit: false,
      from: Capacitor.isNativePlatform() ? 'CUSTOMER_APP' : 'CUSTOMER_WEB',
      orderId: selectedOrder.id,
      orderBatch: {
        ...selectedBatch,
        table: params.table,
        deliveryType: params.deliveryType,
      },
      deliveryType: params.deliveryType,
      payment: payment,
      takeawayInfo: takeawayInfo,
      needTableware: selectedOrder.needTableware,
      ignoreUnavailable: ignoreUnavailable,
      riceCoin: applyRiceCoinDiscount ? usedRiceCoin : 0,
      donateRiceCoinReward: isFoodAngelEnabled ? donateRiceCoinReward : false,
      tags: !_.isEmpty(tags) ? tags : undefined,
      orderTimestamp: config.env !== 'prod'
        ? Number(moment(`${testDate} ${testTime}`).format('X'))
        : Number(moment().format('X')),
      rewards: rewards,
    }
    if (promoCode) {
      // 訂單有 promoCode 時，selectedOrder 已經由 /g/order 計算
      // 若 /g/order 沒有回傳 PROMOCODE modifier 表示不適用，不帶 promoCodeID
      const availablePromoCode = selectedOrder.modifiers.find(modifier => {
        return modifier.type === 'PROMOCODE' && // 是 PROMOCODE
        modifier.payload === promoCode.id && // 有找到
        modifier.calculatedAmount < 0 // 有折扣
      })
      // 若有找到表示後端認為可以使用此優惠，加入 promoCodeId 至 submitBatchParams
      if (availablePromoCode) {
        submitBatchParams.promoCodeId = promoCode.id
      }
    }

    try {
      // submit batch
      logger.log('[submitOrderBatch] dimorderApi.order.submitBatch. body=', submitBatchParams)
      const submittedOrder = await dimorderApi.order.submitBatch(submitBatchParams)
      logger.log('[submitOrderBatch] dimorderApi.order.submitBatch. result=', submittedOrder)
      // 更新正在結帳的 order.selectedOrder
      dispatch(selectOrder(submittedOrder))
      // 更新 orderHistory 相應的 order
      dispatch(actions.orderHistory.updateOrder(submittedOrder))
      // 選擇 order tracking 要顯示的 order
      dispatch(actions.orderHistory.selectOrder(submittedOrder.id))

      logger.log('[submitOrderBatch] update redux done.')

      const url3DS = get3DSUrlFor2c2p(submittedOrder.payments, memberId)
      logger.log('[submitOrderBatch] url3DS', url3DS)

      if (submittedOrder.status === 'paid') {
        // * 已付款
        // 前往訂單追蹤並結束點餐
        history.replace(`${d2cBaseUrl}/order_tracking`, { resetOrder: true })
        // 清除 payment state 的 selectedPaymentMethod
        dispatch(actions.payment.resetPayment())

        if (getState().user.member?.id) {
          // 拿到新的 RiceCoin 記錄和餘額
          dispatch(actions.user.getCustomerInfo())
        }
      } else if (submittedOrder.deliveryType === TABLE && !payFirst) {
        // * 還沒付款，內用後結帳
        // 清除 batch
        dispatch(actions.orderBatch.resetBatch())
        // 內用繼續點餐
        dispatch(actions.order.startOrder())
        // 前往餐廳頁面繼續點餐
        if (params.isD2CWeb) {
          history.pushWithSearch(`${d2cBaseUrl}/menu`)
        } else {
          history.pushWithSearch(`/restaurant/${merchantId}`)
        }
      } else if (url3DS) {
        // * 還沒付款，需先進行 3DS 驗證
        dispatch(actions.payment.update2c2p3DSUrl(url3DS))
        history.replace(`${d2cBaseUrl}/checkout/3ds`)
      } else {
        // * 還沒付款，第三方支付
        // 前往訂單追蹤並結束點餐
        history.replace(`${d2cBaseUrl}/order_tracking`, { resetOrder: true })
        // 開啟第三方付款
        await dispatch(actions.payment.launchPayment(submittedOrder))
      }
      // 關閉 loading
      endSubmitOrderBatch(submittedOrder)
    } catch (error) {
      endSubmitOrderBatch()
      handleAxiosError(error, {
        loggerPrefix: '[submitOrderBatch] submitBatch',
        responseErrorHandler: (errorMessage) => {
          if (errorMessage === 'incorrect payment amount') {
            // 付款金額不正確
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.incorrect payment amount.title'),
              message: i18n.t('app.component.alert.incorrect payment amount.message'),
            }))
          } else if (
            errorMessage === 'submit too fast' ||
            errorMessage === 'submit_too_fast'
          ) {
            // 太快了，跳提示不需處理
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.submit_too_fast.title'),
              message: i18n.t('app.component.alert.submit_too_fast.message'),
            }))
          } else if (errorMessage === 'amount_too_small') {
            const minAmount = error.response?.data?.minAmount
            // 未達低消，跳提示不需處理
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.amount_too_small.title'),
              message: i18n.t('app.component.alert.amount_too_small.message', { minAmount }),
            }))
          } else if (errorMessage.startsWith('promocode')) {
            // promoCode 相關的直接用 errorMessage 就可以找到錯誤訊息，詳見 src/i18n/locales/zh-HK.json app.component.alert.promocode.error_message
            // promocode 相關問題
            const messageKey = 'app.component.alert.promocode.error_message.' + errorMessage
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.promocode.title'),
              message: i18n.exists(messageKey) ? i18n.t(messageKey) : '',
              onConfirm: () => {
                // 清除 promocode
                dispatch(deletePromoCode())
              },
            }))
          } else if (
            errorMessage === 'no shop' ||
            errorMessage === 'merchant geo not set'
          ) {
            // 餐廳設定問題，不可能可以下單，reset order 並回到餐廳列表
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.invalid_merchant.title'),
              message: i18n.t('app.component.alert.invalid_merchant.message'),
              onConfirm: () => {
                dispatch(resetOrder(`submit with error: ${errorMessage}`))
                history.replace('/restaurants')
              },
            }))
          } else if (errorMessage === 'cutoff order') {
            // 餐廳無法提供服務，reset order
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.invalid_pickup_at.title'),
              message: i18n.t('app.component.alert.invalid_pickup_at.message'),
              onConfirm: () => {
                dispatch(resetOrder(`submit with error: ${errorMessage}`))
              },
            }))
          } else if (errorMessage === 'customer ricecoin is less than asked') {
            // RC 餘額不足，重算訂單
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.ricecoin_less_than_asked.title'),
              message: i18n.t('app.component.alert.ricecoin_less_than_asked.message'),
              onConfirm: async () => {
                // 抓新的 RC 餘額
                await dispatch(actions.user.getCustomerInfo())
                // 重新計算訂單
                await dispatch(calculateOrder())
              },
            }))
          } else if (errorMessage.includes('cash payment not enabled')) {
            // 此餐廳不接受現金付款
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.cash_payment_not_enabled.title'),
              message: i18n.t('app.component.alert.cash_payment_not_enabled.message'),
              onConfirm: () => {
                // 清除 payment
                dispatch(actions.payment.resetPayment())
              },
            }))
          } else if (
            errorMessage === 'not allowed to submit card' ||
            errorMessage === 'no recent payment'
          ) {
            // 付款問題，清除 payment
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.payment_error.title'),
              message: i18n.t('app.component.alert.payment_error.message'),
              onConfirm: () => {
                // 清除 payment
                dispatch(actions.payment.resetPayment())
              },
            }))
          } else if (errorMessage === 'poonchoi pickup_at not available') {
            // 盆菜的時間不能用，顯示 alert
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.poonchoi pickup_at not available.title'),
              message: i18n.t('app.component.alert.poonchoi pickup_at not available.message'),
            }))
          } else if (
            errorMessage === 'lalamove ScheduleAt field required' ||
            errorMessage === 'invalid timeslot' ||
            errorMessage === 'invalid timeslot (PickupAt)' ||
            errorMessage === 'invalid timeslot (ScheduleAt)' ||
            errorMessage === 'out of time range'
          ) {
            // pickupAt 時間不允許下單，顯示 alert
            dispatch(showInvalidPickupAtAlert(` (${logId})`))
          } else if (errorMessage.startsWith('pickup_at or schedule_at not available')) {
            // 原錯誤訊息為 `pickup_at or schedule_at not available, msg: %s`，後面有不確定的 %s 因此用 startsWith 處理
            const msg = errorMessage.split(', msg: ')?.[1]
            const errorCode = msg ? ` (${msg})` : ` (${logId})`

            // pickupAt 時間不允許下單，顯示 alert
            dispatch(showInvalidPickupAtAlert(errorCode))
          } else if (errorMessage === 'area not found') {
            // 地址有問題
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.invalid_address.title'),
              message: i18n.t('app.component.alert.invalid_address.message') + ` (${logId})`,
              onConfirm: () => {
                // 前往地址設定
                history.push('/settings/address')
              },
            }))
          } else if (errorMessage === 'missing takeaway info') {
            // 重新 createShipping，然後請 user 再下單應該就會有 createTakeawayInfo
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.shipping_expired.title'),
              message: i18n.t('app.component.alert.shipping_expired.message'),
              onConfirm: () => {
                dispatch(createShipping())
              },
            }))
          } else if (errorMessage === 'empty order items') {
            // 沒有餐點
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.empty_order.title'),
              message: i18n.t('app.component.alert.empty_order.message'),
            }))
          } else if (/^(set menu).*(not found)$/.test(errorMessage)) {
            // 因為這個情況很少見，直接要求使用者重新下單
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.set_menu_not_found.title'),
              message: i18n.t('app.component.alert.set_menu_not_found.message') + ` (${logId})`,
              onConfirm: () => {
                dispatch(resetOrder(`submit with error: ${errorMessage}`))
              },
            }))
          } else if (errorMessage === 'some items are not available') {
            // 餐點庫存不足
            const { items, inventory } = error.response.data
            const itemNames = _.uniq(items.map(item => {
              if (item.setName) {
                // 若為套餐的 setItem 時額外顯示套餐的名稱
                return `${item.name} (${item.setName})`
              }
              return item.name
            }))
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.some_items_unavailable.title'),
              messages: [
                i18n.t('app.component.alert.some_items_unavailable.message.remove_item'),
                ...itemNames],
              buttons: [
                {
                  text: i18n.t('app.common.confirm'),
                  onClick: () => {
                    // 更新batch餐點數量
                    dispatch(actions.orderBatch.updateSoldOutInventory(inventory))
                  },
                },
              ],
            }))
          } else if (errorMessage === 'some sets are not available') {
            // 套餐庫存不足
            const { sets, inventory } = error.response.data
            const setNames = _.uniq(sets.map(set => set.name))
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.some_items_unavailable.title'),
              messages: [
                i18n.t('app.component.alert.some_items_unavailable.message.remove_item'),
                ...setNames],
              buttons: [
                {
                  text: i18n.t('app.common.confirm'),
                  onClick: () => {
                    dispatch(actions.orderBatch.removeSoldOutSets(inventory))
                  },
                },
              ],
            }))
          } else if (errorMessage === 'some options are not available') {
            // 選項庫存不足，目前 merchant 未實做這個功能，應該不可能出現這個錯誤訊息，若出現則清空購物車繼續點餐
            // const { options, inventory } = error.response.data
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.some_items_unavailable.title'),
              message: i18n.t('app.component.alert.some_items_unavailable.message.empty_cart'),
              buttons: [
                {
                  text: i18n.t('app.common.confirm'),
                  onClick: () => {
                    // 清除 batch
                    dispatch(actions.orderBatch.resetBatch())
                    // 繼續點餐
                    dispatch(actions.order.startOrder())
                  },
                },
              ],
            }))
          } else if (
            errorMessage === 'order not allowed for access' ||
            errorMessage === 'order already submitted'
          ) {
            // 先結帳堂食訂單已送出，無法再下單，reset order
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.order already submitted.title'),
              message: i18n.t('app.component.alert.order already submitted.message') + ` (${logId})`,
              onConfirm: () => {
                // reset order
                dispatch(actions.orderHistory.selectOrder(selectedOrder.id))
                // 前往 order tracking page 並結束點餐
                history.replace(`${d2cBaseUrl}/order_tracking`, { resetOrder: true })
              },
            }))
          } else if (errorMessage === 'expired order') {
            // 訂單過期，無法下單，reset order
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.expired_order.title'),
              message: i18n.t('app.component.alert.expired_order.message') + ` (${logId})`,
              onConfirm: () => {
                dispatch(resetOrder(`submit with error: ${errorMessage}`))
              },
            }))
          } else if (
            errorMessage.startsWith('redis lock error') ||
            errorMessage.endsWith('submit locked')
          ) {
            // 先檢查訂單狀態是否有成功送出，有的話去 order tracking 沒有的話顯示再試一次
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.customer_submit_locked.title') + ` (${logId})`,
            }))
          } else if (errorMessage.startsWith('respCode')) {
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.create_payment_error.title') + ` (${logId})`,
              message: i18n.t('app.component.alert.create_payment_error.message'),
            }))
          } else if (/^(reward).*(not found)$/.test(errorMessage)) {
            // 兌換 CRM reward 但 reward 不存在
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.reward_not_found_error.title'),
            }))
          } else if (errorMessage === 'customer points not enough') {
            // 兌換 CRM reward 但點數不足
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.not_enough_points_error.title'),
            }))
          } else {
            // 其他問題或未知問題，顯示請重試
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.error_please_retry.title') + ` (${logId})`,
            }))
          }
        },
      })
    }
  }
}

export function applyCoupon (coupon) {
  return async (dispatch, getState) => {
    const { deliveryType } = getState().app.params
    const { payFirst } = getState().merchant?.data?.setting
    const selectedOrder = getState().order.selectedOrder
    const dineInPostOrder = deliveryType === TABLE && !payFirst
    const shipping = getState().order.shipping

    const shippingDiscount = selectedOrder.modifiers.find((modifier) => modifier.id === 'SHIPPING_DISCOUNT')
    if (coupon.applyTo === 'SHIPPING' && (shippingDiscount?.calculatedAmount ?? 0) + shipping.baseShippingFee === 0) {
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.dialog.promo_dialog.alert.coupon_title'),
        message: i18n.t('app.component.dialog.promo_dialog.alert.coupon_message.not_applicable'),
      }))
      return
    }

    dispatch({
      type: ActionTypes.UPDATE_APPLY_PROMO_CODE_LOADING,
      payload: { isLoading: true },
    })
    if (dineInPostOrder) {
      // 對堂食後付款訂單，promoCode 需更新至後端
      // 找到舊的 promoCode
      const oldPromoCodeModifier = selectedOrder.modifiers.find(modifier => modifier.type === 'PROMOCODE')
      const deletedPromoCodeId = oldPromoCodeModifier?.promoCodeId

      if (coupon.id === deletedPromoCodeId) {
        // promoCode 不變
        dispatch({
          type: ActionTypes.UPDATE_APPLY_PROMO_CODE_LOADING,
          payload: { isLoading: false },
        })
        return
      }

      try {
        let updatedOrder = await dimorderApi.order.updatePromoCode(selectedOrder.id, coupon.id, deletedPromoCodeId)
        updatedOrder = dispatch(actions.order.injectRiceCoinDiscount(updatedOrder))
        dispatch(selectOrder(updatedOrder))
        dispatch(actions.orderHistory.updateOrder(updatedOrder))
      } catch (error) {
        handleAxiosError(error, {
          responseErrorMessagePath: 'response.data.error',
          loggerPrefix: '[order.applyCoupon] updatePromoCode',
          loggerExtraData: { orderId: selectedOrder.id, couponId: coupon.id, deleteCouponId: deletedPromoCodeId },
          responseErrorHandler: (errorMessage) => {
            dispatch(handleUpdatePromoCodeAlert(errorMessage, true))
          },
        })
      }
    } else {
      // 直接更新的 promocode 待 submit 時一併帶給後端
      dispatch({
        type: ActionTypes.UPDATE_PROMO_CODE,
        payload: { promoCode: coupon },
      })

      // 試算訂單
      await dispatch(calculateOrder())
    }
    dispatch({
      type: ActionTypes.UPDATE_APPLY_PROMO_CODE_LOADING,
      payload: { isLoading: false },
    })
  }
}

/**
 * 手動輸入優惠碼
 * @param {string} code
 * @returns {ThunkFunction}
 */
export function applyPromoCode (code) {
  return async (dispatch, getState) => {
    const { deliveryType } = getState().app.params
    const { payFirst } = getState().merchant?.data?.setting
    const selectedOrder = getState().order.selectedOrder
    const dineInPostOrder = deliveryType === TABLE && !payFirst

    dispatch({
      type: ActionTypes.UPDATE_APPLY_PROMO_CODE_LOADING,
      payload: { isLoading: true },
    })

    /** @type {IPromoCode} */
    const promoCode = await dispatch(actions.order.getPromoCodeByCode(code))
    if (_.isNull(promoCode) || promoCode.type === 'COUPON') {
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.dialog.promo_dialog.alert.title'),
      }))
      dispatch({
        type: ActionTypes.UPDATE_APPLY_PROMO_CODE_LOADING,
        payload: { isLoading: false },
      })
      return
    }

    if (dineInPostOrder) {
      // 對堂食後付款訂單，promoCode 需更新至後端
      // 找到舊的 promoCode
      const oldPromoCodeModifier = selectedOrder.modifiers.find(modifier => modifier.type === 'PROMOCODE')
      const deletedPromoCodeId = oldPromoCodeModifier?.promoCodeId

      if (promoCode.id === deletedPromoCodeId) {
        // promoCode 不變
        dispatch({
          type: ActionTypes.UPDATE_APPLY_PROMO_CODE_LOADING,
          payload: { isLoading: false },
        })
        return
      }

      try {
        let updatedOrder = await dimorderApi.order.updatePromoCode(selectedOrder.id, promoCode.id, deletedPromoCodeId)
        updatedOrder = dispatch(actions.order.injectRiceCoinDiscount(updatedOrder))
        dispatch(selectOrder(updatedOrder))
        dispatch(actions.orderHistory.updateOrder(updatedOrder))

        // 將 promoCode 存入 user.promoCodes 讓之後 autofill 能找到這個 promCode
        dispatch(actions.user.addPromoCode(promoCode))
      } catch (error) {
        handleAxiosError(error, {
          responseErrorMessagePath: 'response.data.error',
          loggerPrefix: '[order.applyPromoCode] updatePromoCode',
          loggerExtraData: { orderId: selectedOrder.id, couponId: promoCode.id, deleteCouponId: deletedPromoCodeId },
          responseErrorHandler: (errorMessage) => {
            dispatch(handleUpdatePromoCodeAlert(errorMessage, false))
          },
        })
      }
    } else {
      let alertProps = {}
      if (
        (promoCode.deliveryType && promoCode.deliveryType !== deliveryType) ||
        (promoCode.applyTo === 'SHIPPING' && deliveryType !== STORE_DELIVERY)
      ) {
        alertProps = {
          title: i18n.t('app.component.dialog.promo_dialog.alert.title'),
          message: i18n.t('app.component.dialog.promo_dialog.alert.message.delivery_type'),
        }
      } else if (isNotBegin(promoCode.validFrom, promoCode.validUntil)) {
        alertProps = {
          title: i18n.t('app.component.dialog.promo_dialog.alert.title'),
          message: i18n.t('app.component.dialog.promo_dialog.alert.message.not_open'),
        }
      } else if (isExpired(promoCode.validFrom, promoCode.validUntil)) {
        alertProps = {
          title: i18n.t('app.component.dialog.promo_dialog.alert.title'),
          message: i18n.t('app.component.dialog.promo_dialog.alert.message.expired'),
        }
      } else if (!validateQuota(promoCode)) {
        // 使用次數 usedTime < quota
        alertProps = {
          title: i18n.t('app.component.dialog.promo_dialog.alert.title'),
          message: i18n.t('app.component.dialog.promo_dialog.alert.message.used'),
        }
      } else if (!validateMinOrderAmount(promoCode, selectedOrder?.originalTotal)) {
        // 最低消費金額 order.total>=minOrderAmount
        alertProps = {
          title: i18n.t('app.component.dialog.promo_dialog.alert.title'),
          message: i18n.t('app.component.dialog.promo_dialog.alert.message.order_amount'),
        }
      }

      if (!_.isEmpty(alertProps)) {
        setTimeout(() => {
          dispatch(actions.app.toggleAlert(alertProps))
        }, 200)
      } else {
        // 直接更新的 promocode 待 submit 時一併帶給後端
        dispatch({
          type: ActionTypes.UPDATE_PROMO_CODE,
          payload: { promoCode },
        })

        // 將 promoCode 存入 user.promoCodes 讓之後 autofill 能找到這個 promCode
        dispatch(actions.user.addPromoCode(promoCode))
        // 試算訂單
        await dispatch(calculateOrder())
      }
    }
    dispatch({
      type: ActionTypes.UPDATE_APPLY_PROMO_CODE_LOADING,
      payload: { isLoading: false },
    })
  }
}

/**
 * 刪除優惠碼
 * @returns {ThunkFunction}
 */
export function deletePromoCode () {
  return async (dispatch, getState) => {
    const { deliveryType } = getState().app.params
    const merchantSetting = getState().merchant?.data?.setting
    const selectedOrder = getState().order.selectedOrder

    // 直接清除本地 order.promoCode
    dispatch({ type: ActionTypes.DELETE_PROMO_CODE })

    // 以下情況有刪除 promoCode 即可，不用繼續去 calculateOrder 或 update 後端 order
    if (!selectedOrder) return // 沒有 selectOrder
    if (!merchantSetting) return // 不在餐廳中，沒有餐廳資料（可能是在餐廳列表或首頁更改運送方式而觸發 deletePromoCode）

    dispatch({
      type: ActionTypes.UPDATE_DELETE_PROMO_CODE_LOADING,
      payload: { isLoading: true },
    })

    const dineInPostOrder = deliveryType === TABLE && !merchantSetting.payFirst
    if (dineInPostOrder && selectedOrder.createdAt) {
      // 堂食後付款訂單的 promocode 已經在後端了，需要 call api 刪除
      const oldPromoCodeModifier = selectedOrder.modifiers.find(modifier => modifier.type === 'PROMOCODE')
      if (oldPromoCodeModifier) {
        const deletedPromoCodeId = oldPromoCodeModifier.payload
        try {
          let updatedOrder = await dimorderApi.order.deletePromoCode(selectedOrder.id, deletedPromoCodeId)
          updatedOrder = dispatch(actions.order.injectRiceCoinDiscount(updatedOrder))
          dispatch(selectOrder(updatedOrder))
          dispatch(actions.orderHistory.updateOrder(updatedOrder))
        } catch (error) {
          console.log('deletePromoCode error', error)
        }
      }
    } else {
      // 重算訂單
      dispatch(calculateOrder())
    }

    dispatch({
      type: ActionTypes.UPDATE_DELETE_PROMO_CODE_LOADING,
      payload: { isLoading: false },
    })
  }
}

/**
 * @param {string} id
 * @returns {ThunkFunction}
 */
export function getPromoCodeById (id) {
  return async (dispatch, getState) => {
    const promoCode = await dimorderApi.promoCode.getPromoCode(id)
    return promoCode
  }
}

/**
 * @param {string} merchantId
 * @param {string} code promoCode.code
 * @returns {ThunkFunction}
 */
export function getPromoCodeByCode (code) {
  return async (dispatch, getState) => {
    const merchantId = getState().merchant.data.id
    const promoCode = await dimorderApi.promoCode.queryPromoCode(merchantId, code)
    return promoCode
  }
}

/**
 * @returns {ThunkFunction}
 */
export function autoFillPromoCode () {
  return async (dispatch, getState) => {
    await dispatch({
      type: ActionTypes.UPDATE_AUTOFILL_PROMO_CODE_LOADING,
      payload: { isLoading: true },
    })

    const { deliveryType } = getState().app.params
    const merchantId = getState().merchant.data.id
    const userPromoCodes = getState().user.promoCodes
    const orderPromoCode = getState().order.promoCode
    const landingPromoCodes = getState().landing.merchantPromocodes

    dispatch({
      type: ActionTypes.UPDATE_AUTOFILL_PROMO_CODE_LOADING,
      payload: { isLoading: true },
    })

    // 計算訂單，為了得到 originalTotal
    await dispatch(actions.order.calculateOrder())

    if (orderPromoCode && orderPromoCode.type === 'COUPON') {
      // Coupon 為使用者手動選擇，不應擅自由 autoFillPromoCode 覆蓋
      // 計算訂單後直接結束
      dispatch({
        type: ActionTypes.UPDATE_AUTOFILL_PROMO_CODE_LOADING,
        payload: { isLoading: false },
      })
      return
    }

    const promoCodes = []
    userPromoCodes.forEach(userPromoCode => {
      if (userPromoCodes.merchantId === merchantId) {
        promoCodes.push(userPromoCode)
      }
    })
    landingPromoCodes.forEach(landingPromoCode => {
      if (landingPromoCode.id === merchantId) {
        promoCodes.push(...landingPromoCode.promoCodes[deliveryType])
      }
    })

    const deliveryTypePromoCodes = _.chain(promoCodes)
      .filter(p => !p.deliveryType || p.deliveryType === deliveryType) // 找出 deliveryType 相符或通用的 promoCodes
      .uniqBy('code') // 移除重複
      .value()

    const selectedOrder = getState().order.selectedOrder
    const validPromoCodes = await validatePromoCodes(deliveryTypePromoCodes, merchantId, selectedOrder.originalTotal)

    // 如果沒有 valid 的 promocode 則僅執行 order 試算
    if (_.isEmpty(validPromoCodes)) {
      await dispatch(actions.order.calculateOrder())
      await dispatch({
        type: ActionTypes.UPDATE_AUTOFILL_PROMO_CODE_LOADING,
        payload: { isLoading: false },
      })
      return
    }

    const calculatePromises = _.map(validPromoCodes, promoCode => {
      return dispatch(actions.order.calculateOrderWithPromoCode(promoCode))
    })
    const calculateResults = await Promise.all(calculatePromises)
    const calcualtedOrders = calculateResults.filter(o => o)

    const promoCodeResults = _
      .chain(validPromoCodes)
      .map(promoCode => {
        const find = _.find(calcualtedOrders, o => _.find(o.modifiers, m => m.id === 'PROMOCODE' && m.code === promoCode.code))
        return { ...promoCode, total: find?.total }
      }) // 從試算結果中找到 total 並 map 到 promoCode 中
      .sortBy('total') // 依照試算結果的 total 從小到大排序
      .value()

    dispatch(updatePrioritizedPromoCodes(promoCodeResults))

    let prioritizedPromoCode
    if (!_.isEmpty(promoCodeResults)) {
      const isLogin = Boolean(getState().user.member?.id)
      if (isLogin) {
        prioritizedPromoCode = promoCodeResults[0]
      } else {
        prioritizedPromoCode = promoCodeResults.find((promoCode) => !promoCode.onlyRegisteredUser)
      }
      prioritizedPromoCode = _.omit(prioritizedPromoCode, 'total') // 取出 total 最小的 result 並移除僅供計算用的 total

      await dispatch(actions.order.updatePromoCode(prioritizedPromoCode))
      // 將 promoCode 存入 user.promoCodes 讓之後 autofill 能找到這個 promCode
      dispatch(actions.user.addPromoCode(prioritizedPromoCode))
    }

    await dispatch({
      type: ActionTypes.UPDATE_AUTOFILL_PROMO_CODE_LOADING,
      payload: { isLoading: false },
    })
  }
}

/**
 * 更改優惠碼
 * @param {IPromoCode} promoCode
 * @returns {ThunkFunction}
 */
export function updatePromoCode (promoCode) {
  return async (dispatch, getState) => {
    const { deliveryType } = getState().app.params
    const { payFirst } = getState().merchant?.data?.setting
    const selectedOrder = getState().order.selectedOrder
    const dineInPostOrder = deliveryType === TABLE && !payFirst

    if (!promoCode) return

    if (dineInPostOrder && selectedOrder.createdAt) {
      // 堂食後付款訂單可能會有多次 submit batch，不會在 submit 時帶 promocode，會在 /pay 付款時才使用 promocode，因此需要另外處理
      const oldPromoCodeModifier = selectedOrder.modifiers.find(modifier => modifier.type === 'PROMOCODE')
      const deletedPromoCodeId = oldPromoCodeModifier?.promoCodeId
      if (promoCode.id === deletedPromoCodeId) {
        // promoCode 不變
        return
      }

      // 新增或替換 promoCode
      try {
        let updatedOrder = await dimorderApi.order.updatePromoCode(selectedOrder.id, promoCode.id, deletedPromoCodeId)
        updatedOrder = dispatch(actions.order.injectRiceCoinDiscount(updatedOrder))
        dispatch(selectOrder(updatedOrder))
        dispatch(actions.orderHistory.updateOrder(updatedOrder))
      } catch (error) {
        handleAxiosError(error, {
          responseErrorMessagePath: 'response.data.error',
          loggerPrefix: '[order.updatePromoCode] updatePromoCode',
          loggerExtraData: { orderId: selectedOrder.id, couponId: promoCode.id, deleteCouponId: deletedPromoCodeId },
          responseErrorHandler: (errorMessage) => {
            dispatch(handleUpdatePromoCodeAlert(errorMessage, false))
          },
        })
      }
    } else {
      // 直接更新的 promocode 待 submit 時一併帶給後端
      await dispatch({
        type: ActionTypes.UPDATE_PROMO_CODE,
        payload: { promoCode },
      })

      // 試算訂單
      await dispatch(calculateOrder())
    }
  }
}

/**
 * 使用多少 RiceCoin 由前端計算，計算後在 order 中加入臨時的 roundedTotalWithRiceCoinDiscount 和 RiceCoin modifier 用於前端顯示
 * @param {IAppOrder} order
 * @returns {ThunkFunction}
 */
export function injectRiceCoinDiscount (order) {
  return (dispatch, getState) => {
    const applyRiceCoinDiscount = getState().order.applyRiceCoinDiscount

    // 沒有套用 RiceCoin 不用 inject
    // 堂食不可使用 RiceCoin 折抵
    if (!applyRiceCoinDiscount || order.deliveryType === DeliveryType.TABLE) {
      dispatch(actions.order.udpateRiceCoinDiscount({
        discountAmount: 0,
        usedRiceCoin: 0,
      }))
      return order
    }

    return produce(order, (draftOrder) => {
      // 計算訂單可以用多少 RiceCoin 和折抵多少錢
      const { discountAmount, usedRiceCoin } = calculateRiceCoinDiscount(order)
      const hasRiceCoinModifier = _.find(order.modifiers, (modifier) => modifier.type === 'RICECOIN')

      // 紀錄使用的 RiceCoin 額度和折扣金額到 redux
      dispatch(actions.order.udpateRiceCoinDiscount({
        discountAmount: -discountAmount,
        usedRiceCoin,
      }))

      // 更新到臨時套用 RiceCoin 的 roundedTotal
      draftOrder.roundedTotalWithRiceCoinDiscount = fixPrecision(draftOrder.roundedTotal - discountAmount) // 後端不會知道用多少 RC 所以要由前端先試算
      draftOrder.total = draftOrder.roundedTotalWithRiceCoinDiscount

      // 檢查是否有 RiceCoin type的Modifiers
      if (hasRiceCoinModifier) {
        // 修改目前的 RiceCoin Modifier
        const riceCoinModifier = draftOrder.modifiers
        const riceCoinModifierIndex = _.findIndex(order.modifiers, (modifier) => modifier.type === 'RICECOIN')
        riceCoinModifier[riceCoinModifierIndex] = {
          amount: -discountAmount,
          percent: 0,
          calculatedAmount: -discountAmount,
          applyTo: 'ALL',
          type: 'RICECOIN',
        }
      } else {
        // 新增臨時的 modifier 用來顯示在 Modifier List
        draftOrder.modifiers.push({
          amount: -discountAmount,
          percent: 0,
          calculatedAmount: -discountAmount,
          applyTo: 'ALL',
          type: 'RICECOIN',
        })
      }
    })
  }
}

/**
 * @param {IRiceCoinDiscount} riceCoinDiscount
 * @returns {ThunkFunction}
 */
export function udpateRiceCoinDiscount (riceCoinDiscount) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_RICECOIN_DISCOUNT,
      payload: { riceCoinDiscount },
    })
  }
}

/**
 * @param {boolean} applyRiceCoinDiscount
 * @returns {ThunkFunction}
 */
export function updateApplyRiceCoinDiscount (applyRiceCoinDiscount) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_APPLY_RICECOIN_DISCOUNT,
      payload: { applyRiceCoinDiscount },
    })

    // 試算訂單
    dispatch(calculateOrder())
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetRiceCoinDiscount () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_RICECOIN_DISCOUNT,
      payload: { riceCoinDiscount: null },
    })
    dispatch({
      type: ActionTypes.UPDATE_APPLY_RICECOIN_DISCOUNT,
      payload: { applyRiceCoinDiscount: null },
    })
  }
}

/**
 * @param {boolean} donateRiceCoinReward
 * @returns {ThunkFunction}
 */
export function updateDonateRiceCoinReward (donateRiceCoinReward) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_DONATE_RICECOIN_REWARD,
      payload: { donateRiceCoinReward },
    })
  }
}

/**
 * @param {('pay' | 'waiter')} type
 * @returns {ThunkFunction}
 */
export function requestService (type, options) {
  return async (dispatch, getState) => {
    const selectedOrder = getState().order.selectedOrder
    const params = getState().app.params
    const d2cBaseUrl = params.isD2CWeb ? `/d2c/${selectedOrder.merchantId}` : ''
    if (!selectedOrder) return

    const requestServiceTime = getState().order.requestServiceTime[type]
    const canRequest = requestServiceTime == null || moment().isAfter(moment(requestServiceTime).add(1, 'minute'))

    try {
      if (canRequest) {
        if (type === 'pay') {
          await dimorderApi.order.requestPaying(selectedOrder.id, options)
        }
        if (type === 'waiter') {
          await dimorderApi.order.requestService(selectedOrder.id)
        }
        dispatch({
          type: ActionTypes.UPDATE_REQUEST_SERVICE_TIME,
          payload: { path: type, time: new Date() },
        })
      } else {
        dispatch(actions.app.toggleAlert({
          title: i18n.t('app.component.alert.submit_too_fast.title'),
          message: i18n.t('app.component.alert.submit_too_fast.message'),
        }))
      }
    } catch (error) {
      handleAxiosError(error, {
        loggerPrefix: `[requestService] ${type === 'pay' ? 'requestPaying' : 'requestService'}`,
        loggerExtraData: { type, options },
        responseErrorHandler: (errorMessage) => {
          if (errorMessage === 'status is not pending') {
            // 只會再 request paying 出現，訂單不在等待付款狀態不可 request paying
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.status is not pending.title'),
              message: i18n.t('app.component.alert.status is not pending.message'),
              onConfirm: () => {
                // reset order
                dispatch(actions.orderHistory.selectOrder(selectedOrder.id))
                // 前往 order tracking page 並結束點餐
                history.replace(`${d2cBaseUrl}/order_tracking`, { resetOrder: true })
              },
            }))
          } else {
            dispatch(actions.app.toggleAlert({
              title: i18n.t('app.component.alert.error_please_retry.title'),
            }))
          }
        },
      })
    }
  }
}

/**
 * @param {IShipping} shipping
 * @returns {ThunkFunction}
 */
export function updateShipping (shipping) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SHIPPING,
      payload: { shipping },
    })
  }
}

/**
 * 當 datetime 不可用且拿不到新的即時 timeslot 時會將 datetime.date, datetime.time 設為 undefined
 * 之後就需要打開 preorder drawer 讓 user 重選時間
 * @returns {ThunkFunction}
 */
export function createShipping () {
  return async (dispatch, getState) => {
    dispatch(actions.app.updateLoading('createShipping', true))
    try {
      const testDate = getState().app.test.date
      const testTime = getState().app.test.time
      const user = getState().user
      const merchant = getState().merchant.data
      const { deliveryType, datetime, categoryTag } = getState().app.params
      let pickupAt = libs.date.formatDatetimeToMoment(datetime)

      let result
      if (deliveryType === STORE_DELIVERY) {
        // const geocodeResult = await geocodeByAddress(address)
        // const country = await libs.googleMap.findCountry(geocodeResult[0])
        const country = 'HK' // default country to HK
        const origin = await libs.googleMap.getOriginLatLng(merchant.setting.location, merchant.address)
        const destination = user.address?.latlng
        if (!destination) {
          throw new Error('NO_ADDRESS')
        }

        const directionResult = await libs.googleMap.getDirectionResult({ origin, destination })
        const duration = directionResult.routes[0].legs[0].duration // e.g. { text: "14 分鐘", value: 827 }

        // waypoints
        const start = { lat: String(origin.lat), lng: String(origin.lng), address: merchant.address, country }
        const end = { lat: String(destination.lat), lng: String(destination.lng), address: user.address.address, country }

        const shippings = _.filter(merchant.shippings, shipping => shipping.type === STORE_DELIVERY && shipping.enabled)
        const shipping = _.clone(shippings[0])
        const shippingTime = duration ? Math.ceil(duration.value / 60) : 0
        const bufferTime = constants.shipping.LALAMOVE_BUFFER
        const isPoonchoi = categoryTag === '盆菜'

        // check pickup available (盆菜不需檢查 available)
        if (!isPoonchoi) {
          // 超時或即時則 用最新的即時作為 pickupAt
          const isTimeAvailable = await checkTimeAvailable(merchant.id, pickupAt, deliveryType, shippingTime)
          if (!isTimeAvailable) {
            pickupAt = await getImmediateTime(merchant.id, datetime.date, deliveryType, shippingTime)
            dispatch(actions.app.updateDatetime({
              date: pickupAt ? pickupAt.format('YYYY-MM-DD') : undefined, // 2020-01-30
              time: pickupAt ? pickupAt.format('HH:mm') : undefined, // 08:30
              isImmediate: true,
            }))
          }
        }

        const scheduleAt = pickupAt
          ? pickupAt.clone().subtract(shippingTime + bufferTime, 'minutes')
          : undefined

        const priceResult = await getLalamoveQuotation({
          pickupAt: pickupAt?.toISOString(),
          scheduleAt: scheduleAt?.toISOString(),
          waypoints: [start, end],
          requesterContact: { name: merchant.name, phone: merchant.contact },
          orderAmount: getState().order?.selectedOrder?.originalSubtotal,
          poonchoi: isPoonchoi, // 拿盆菜運費要帶的欄位
          orderTimestamp: config.env !== 'prod'
            ? Number(moment(`${testDate} ${testTime}`).format('X'))
            : Number(moment().format('X')), // 拿盆菜運費要帶的欄位
          merchantId: merchant.id,
        })

        const district = findDistrict(priceResult.stops, user.address.address)

        const areas = shipping?.areas ?? []
        const area = _.find(areas, area => area.district === district) || _.find(areas, area => area.isDefault)

        if (!shipping || !area) {
          throw new Error('NO_RESULT')
        }

        const isLalamove = merchant.setting.lalamove
        shipping.areas = [area]
        shipping.totalFee = isLalamove
          ? area.price ?? priceResult.totalFee
          : area.price ?? shipping.cost

        result = {
          ...shipping,
          ...priceResult,
          district,
          shippingTime,
          expiredAt: moment().add(10, 'minutes'),
        }
      }
      if (deliveryType === TAKEAWAY) {
        // 不用算運費只要檢查時間
        // check pickup available
        const isTimeAvailable = await checkTimeAvailable(merchant.id, pickupAt, deliveryType, 0)

        // 超時或即時則 用最新的即時作為 pickupAt
        if (!isTimeAvailable) {
          pickupAt = await getImmediateTime(merchant.id, datetime.date, deliveryType, 0)
          dispatch(actions.app.updateDatetime({
            date: pickupAt ? pickupAt.format('YYYY-MM-DD') : undefined, // 2020-01-30
            time: pickupAt ? pickupAt.format('HH:mm') : undefined, // 08:30
            isImmediate: true,
          }))
        }

        result = {
          baseShippingFee: 0,
          longDistanceFee: 0,
          smallOrderFee: 0,
          totalFee: 0,
          tunnelFee: 0,
          district: 0,
          shippingTime: 0,
          expiredAt: moment().add(10, 'minutes'),
        }
      }
      if (deliveryType === TABLE) {
        // 不用檢查時間
        result = {
          baseShippingFee: 0,
          longDistanceFee: 0,
          smallOrderFee: 0,
          totalFee: 0,
          tunnelFee: 0,
          district: 0,
          shippingTime: 0,
          expiredAt: moment().add(10, 'minutes'),
        }
      }

      dispatch(updateShipping(result))
      // 試算訂單
      dispatch(calculateOrder())
      dispatch(actions.app.updateLoading('createShipping', false))
      return result
    } catch (error) {
      console.log('createShipping error', error.message)
      dispatch(actions.app.updateLoading('createShipping', false))
      dispatch(updateShipping({ shippingTime: 'NO_RESULT', expiredAt: moment().add(10, 'minutes') }))
    }
  }
}

/**
 * @funciton
 * @param {*} params
 * @returns {ThunkFunction}
 */
export function createTakeawayInfo () {
  return async (dispatch, getState) => {
    const user = getState().user
    const deliveryType = getState().app.params.deliveryType
    const params = getState().app.params
    const merchantId = getState().merchant.data.id
    let shipping = getState().order.shipping
    const system = getState().app.system
    const restaurant = getState().landing.restaurant
    const isImmediate = params.datetime.isImmediate
    const date = params.datetime.date
    const shippingTime = shipping?.shippingTime ?? 0
    const isPoonchoi = isPoonChoiCategoryTag(params.categoryTag)
    const isDimbox = checkIsDimbox(system.dimbox.enable, system.takeaway.enable, _.some(restaurant?.tags, tag => tag?.toLowerCase() === 'dimbox'), deliveryType)

    // 內用不用給 takeawayInfo
    if (deliveryType === TABLE) return {}

    let pickupAt = libs.date.formatDatetimeToMoment(params.datetime)

    // 外帶 pickupAt 超時
    if (deliveryType === TAKEAWAY) {
      if (isDimbox) {
        // DimBox 餐廳當日截單時間為 10:30，可於當日 12:00~14:30 取餐。
        // 超過 10:30 下單，取餐時間為隔日 12:00~14:30。
        const cutOffTime = moment().hours(dimboxCutOfTime.hour).minutes(dimboxCutOfTime.minute).seconds(dimboxCutOfTime.second)
        pickupAt = moment().isAfter(cutOffTime)
          ? moment().hours(12).minutes(0).add(1, 'd')
          : moment().hours(12).minutes(0)
      } else {
        const isTimeAvailable = await checkTimeAvailable(merchantId, pickupAt, deliveryType, 0)
        if (!isTimeAvailable || isImmediate) {
          // pickupAt 不可用或是即時，需要抓最新的即時作為 pickupAt
          const immediate = await getImmediateTime(merchantId, date, deliveryType, shippingTime)
          if (immediate) {
            pickupAt = immediate.clone()
            dispatch(actions.app.updateDatetime({
              date: pickupAt.format('YYYY-MM-DD'), // 2020-01-30
              time: pickupAt.format('HH:mm'), // 08:30
              isImmediate: true,
            }))
          } else {
            // 沒有拿到可用的 immediate 時間，需要 throw NO_AVAILABLE_IMMEDIATE_TIME 讓外面知道要跳出預約 drawer
            dispatch(actions.app.updateDatetime({
              date: undefined,
              time: undefined,
              isImmediate: true,
            }))
            throw new Error('NO_AVAILABLE_IMMEDIATE_TIME')
          }
        }
      }
    }

    // 外送 pickupAt 超時 or 報價過期
    if (deliveryType === STORE_DELIVERY && !isPoonchoi) {
      // 若報價過期（超過10分鐘） throw SHIPPING_EXPIRED 讓外面知道要跳需要重新報價的 alert
      const isExpired = moment().isSameOrAfter(shipping?.expiredAt)
      if (isExpired) throw new Error('SHIPPING_EXPIRED')
      if (shippingTime === 'NO_RESULT') throw new Error('UNABLE_TO_DELIVER')

      const isTimeAvailable = await checkTimeAvailable(merchantId, pickupAt, deliveryType, shippingTime)
      if (!isTimeAvailable || isImmediate) {
        // pickupAt 不可用或是即時，需要抓最新的即時作為 pickupAt
        const immediate = await getImmediateTime(merchantId, date, deliveryType, shippingTime)
        if (immediate) {
          pickupAt = immediate.clone()
          dispatch(actions.app.updateDatetime({
            date: pickupAt.format('YYYY-MM-DD'),
            time: pickupAt.format('HH:mm'),
            isImmediate: true,
          }))
          await dispatch(createShipping())
          shipping = getState().order.shipping // 拿到新的 shipping
        } else {
          // 沒有拿到可用的 immediate 時間，需要 throw NO_AVAILABLE_IMMEDIATE_TIME 讓外面知道要跳出預約 drawer
          dispatch(actions.app.updateDatetime({
            date: undefined,
            time: undefined,
            isImmediate: true,
          }))
          throw new Error('NO_AVAILABLE_IMMEDIATE_TIME')
        }
      }
    }

    const takeawayInfo = {
      name: user.member.name,
      phone: libs.string.removeContryCode(user.member.mobile),
      pickupAt: pickupAt,
      shipping: deliveryType === STORE_DELIVERY ? shipping : undefined,
      scheduleAt: (deliveryType === STORE_DELIVERY || deliveryType === SHOP) ? shipping?.scheduleAt : undefined,
      district: (deliveryType === STORE_DELIVERY || deliveryType === SHOP) ? shipping?.district : undefined,
      shippingTime,
    }

    if (deliveryType === STORE_DELIVERY) {
      takeawayInfo.address = user.address.address
      takeawayInfo.lat = user.address.latlng.lat
      takeawayInfo.lng = user.address.latlng.lng
      takeawayInfo.floorRoom = user.address.floorRoom
      takeawayInfo.building = user.address.building
    }

    return takeawayInfo
  }
}

/**
 * 取得營業時段或檯號群的服務費及折扣
 * @returns {surcharge: IModifier, discountModifier: IModifier}
 */
export function getDefaultSurchargeAndModifier () {
  return (dispatch, getState) => {
    const merchant = getState().merchant.data
    const selectedOrder = getState().order.selectedOrder
    let surcharge
    let discountModifier

    if (selectedOrder.deliveryType !== TABLE) {
      // 只有堂食有 default discount, surcharge
      return {
        surcharge,
        discountModifier,
      }
    }

    if (selectedOrder.serial) {
      // 訂單已存在後端，直接用訂單的 modifier 計算服務費和預設折扣
      surcharge = selectedOrder.modifiers.find(modifier => modifier.type === 'SURCHARGE')
      discountModifier = selectedOrder.modifiers.find(modifier => modifier.type === 'DISCOUNT')
    } else {
      // 前端臨時訂單，需要前端自己找出檯號群或時段的 modifier 和 discount
      // 拿到目前的 opening 設定 (裡面有折扣和服務費設定)
      const currentOpening = getCurrentOpening()

      // 找出檯號群的服務費及折扣設定
      const tableNumber = getState().app.params.table
      let tableModifier
      if (tableNumber) {
        const tableGroups = merchant.tableGroups
        const tableGroupName = _.findKey(tableGroups, (tables) => tables.includes(tableNumber))
        const tableGroupWithSurcharge = merchant.tableGroupWithSurcharge
        tableModifier = _.get(tableGroupWithSurcharge, tableGroupName)
      }

      // 判斷訂單的 surcharge，先看枱號群有沒有設定服務費，否則看開放時間或預設的服務費
      surcharge = (tableModifier?.useSurcharge && tableModifier.surcharge) || (currentOpening?.surcharge ?? merchant.surcharge)
      // 判斷訂單的 discount，先看枱號群有沒有設定折扣，否則看開放時間的折扣
      const discount = (tableModifier?.useDiscount && tableModifier.discount) || currentOpening?.discount
      discountModifier = {
        id: 'MERCHANT_DEFAULT_DISCOUNT',
        type: 'DISCOUNT',
        applyTo: 'PRODUCT',
        amount: 0,
        percent: discount ? -Math.abs(discount.percent) : 0,
      }
    }

    return {
      surcharge,
      discountModifier,
    }
  }
}

/**
 * @param {IPromoCode} promoCode
 * @returns {ThunkFunction}
 */
export function calculateOrderWithPromoCode (promoCode) {
  return async (dispatch, getState) => {
    const merchant = getState().merchant.data
    const selectedOrder = getState().order.selectedOrder
    const selectedBatch = getState().orderBatch.selectedBatch
    const shipping = getState().order.shipping
    const deliveryType = getState().app.params.deliveryType

    if (!merchant) return
    if (!selectedOrder) return
    if (!selectedBatch) return
    if (deliveryType === STORE_DELIVERY && !shipping) return

    const { surcharge, discountModifier } = dispatch(getDefaultSurchargeAndModifier())

    const data = {
      batches: [...selectedOrder.batches, selectedBatch],
      deliveryType: deliveryType,
      shipping: deliveryType === STORE_DELIVERY ? shipping : null,
      rounding: merchant.rounding,
      surcharge: surcharge,
      discount: discountModifier,
      modifiers: discountModifier ? [discountModifier] : [], // modifiers 由後端計算，所以不需要加上 selectedOrder.modifiers，只要放餐廳預設的就好
      promoCodeId: promoCode.id,
      from: Capacitor.isNativePlatform() ? 'CUSTOMER_APP' : 'CUSTOMER_WEB',
    }

    const calculatedOrder = await dimorderApi.calculateOrder(data)
    return calculatedOrder
  }
}

/**
 * 試算訂單金額、折扣等
 * @returns {ThunkFunction}
 */
export function calculateOrder () {
  return async (dispatch, getState) => {
    const merchant = getState().merchant.data
    const selectedOrder = getState().order.selectedOrder
    const selectedBatch = getState().orderBatch.selectedBatch
    const shipping = getState().order.shipping
    const deliveryType = getState().app.params.deliveryType
    const promoCodeId = getState().order.promoCode?.id

    if (!selectedOrder) return
    if (!merchant) return
    if (!selectedBatch) return
    if (deliveryType === STORE_DELIVERY && !shipping) return

    const { surcharge, discountModifier } = dispatch(getDefaultSurchargeAndModifier())

    const dineInPostOrder = deliveryType === TABLE && !merchant.setting?.payFirst
    // 堂食後付款以外還多判斷了 serial，因為在還沒有桌號時會先用本地建的臨時訂單，不該使用 getOrder 取代 calculateOrder
    if (dineInPostOrder && selectedOrder.serial) {
      let updatedOrder = await dimorderApi.order.getOrder(selectedOrder.id) // 堂食後結帳的訂單 batch 已經在後端，直接 getOrder 就算好了
      updatedOrder = dispatch(actions.order.injectRiceCoinDiscount(updatedOrder)) // 記入前端計算的 RiceCoin 折扣
      dispatch(selectOrder(updatedOrder))
    } else {
      const data = {
        orderId: selectedOrder.id,
        batches: [selectedBatch], // 非堂食後結帳只會有一個 batch，submit 一次即完成訂單，所以 batch 都是本地暫存的 selectedBatch,
        deliveryType: deliveryType,
        shipping: deliveryType === STORE_DELIVERY ? shipping : null,
        rounding: merchant.rounding,
        surcharge: surcharge,
        discount: discountModifier,
        modifiers: discountModifier ? [discountModifier] : [], // modifiers 由後端計算，所以不需要加上 selectedOrder.modifiers，只要放餐廳預設的就好
        promoCodeId: promoCodeId,
        from: Capacitor.isNativePlatform() ? 'CUSTOMER_APP' : 'CUSTOMER_WEB',
      }

      // /g/order 的 batches items 的 price 是為包含 discount 的，如果已經計算過 discount 需要把 discount 加回去
      data.batches = data.batches.map(batch => ({
        ...batch,
        items: batch.items.map(item => ({
          ...item,
          price: item.priceDeductDiscount ? item.priceDeductDiscount + item.discount : item.price,
        })),
      }))
      const calculatedOrder = await dimorderApi.calculateOrder(data)

      let updatedOrder = {
        ...selectedOrder,
        originalTotal: calculatedOrder.originalTotal,
        originalSubtotal: calculatedOrder.originalSubtotal,
        originalShippingFee: calculatedOrder.originalShippingFee,
        surchargeTotal: calculatedOrder.surchargeTotal,
        modifiers: calculatedOrder.modifiers,
        roundedTotal: calculatedOrder.roundedTotal, // 後端算好後給前端的總額
        total: calculatedOrder.roundedTotal,
        ricecoin: calculatedOrder.ricecoin,
        roundedTotalWithRiceCoinDiscount: calculatedOrder.roundedTotal, // 先改回 roundedTotal，之後再由 injectRiceCoinDiscount 計算
      }
      // 記入前端計算的 RiceCoin 折扣
      updatedOrder = dispatch(actions.order.injectRiceCoinDiscount(updatedOrder))
      dispatch(selectOrder(updatedOrder))
    }
  }
}

let loadingKey = 0 // 用來確認是否是最後一次計算結束
function increaseLoadingKey () {
  if (loadingKey === Number.MAX_SAFE_INTEGER) {
    // 避免超過 MAX_SAFE_INTEGER
    loadingKey = 0
  } else {
    loadingKey++
  }
  return loadingKey
}

/**
 * 用在 Checkout page，計算時會禁止送出訂單，根據情況也會自動填入優惠碼
 * @returns {ThunkFunction}
 */
export function calculateOrderForCheckout (retryCount = 0) {
  return async (dispatch, getState) => {
    const deliveryType = getState().app.params.deliveryType
    const categoryTag = getState().app.params.categoryTag
    const payFirst = getState().merchant.data?.setting?.payFirst
    const isPoonChoi = isPoonChoiCategoryTag(categoryTag)

    // 每次 updateOrder 設定新的 loadingKey，用來確認是否是最後一次計算結束
    const currentKey = increaseLoadingKey()
    dispatch(actions.order.updateCalculateOrderLoading(true))

    try {
      if (deliveryType === TABLE) {
        // 內用
        if (payFirst) {
          // 自動填入 promocode 並重新計算 order
          await dispatch(actions.order.autoFillPromoCode())
        } else {
          // 重新計算 order
          await dispatch(actions.order.calculateOrder())
        }
      } else {
        if (isPoonChoi) {
          // 盆菜不做 autoFillPromoCode 直接 calculateOrder
          await dispatch(actions.order.calculateOrder())
        } else {
          // 先自動選擇 promocode 並重新計算訂單（ autoFillPromoCode 選擇完 promocode 後會 calculateOrder）
          await dispatch(actions.order.autoFillPromoCode())
        }
        // 再來才 createShipping，裡面會使用剛剛上面計算出來的新 order.originalSubtotal 計算小額運費
        await dispatch(actions.order.createShipping())
      }

      if (currentKey === loadingKey) {
        // 只有 currentKey = loadingKey 時表是是最後一次 updateOrder 可以結束 loading
        dispatch(actions.order.updateCalculateOrderLoading(false))
        // 計算成功，允許 submit
        dispatch(actions.order.updateCalculateOrderError(false))
      }
    } catch (error) {
      logger.error('[calculateOrderForCheckout]', { error, retryCount, currentKey, loadingKey })
      if (currentKey === loadingKey) {
        if (retryCount > 3) {
          // 只有 currentKey = loadingKey 時表是是最後一次 updateOrder 可以顯示錯誤
          dispatch(actions.order.updateCalculateOrderLoading(false))
          // 因為最後一次計算有錯誤，不允許 submit
          dispatch(actions.order.updateCalculateOrderError(true))

          // 重試三次還是計算失敗，顯示錯誤
          dispatch(actions.app.toggleAlert({
            title: i18n.t('app.component.alert.calculate_order_error.title'),
            message: i18n.t('app.component.alert.calculate_order_error.message') + ` (${logId})`,
            buttons: [
              {
                text: i18n.t('app.common.retry'),
                style: { backgroundColor: '#ffc42b' },
                onClick: () => {
                  dispatch(calculateOrderForCheckout(0))
                },
              },
            ],
          }))
        } else {
          // 自動重試
          await delay(500)
          dispatch(calculateOrderForCheckout(retryCount + 1))
        }
      }
    }
  }
}

export function updateCalculateOrderLoading (isCalculateOrderLoading) {
  return {
    type: ActionTypes.UPDATE_CALCULARE_ORDER_LOADING,
    payload: { isCalculateOrderLoading },
  }
}

export function updateCalculateOrderError (isCalculateOrderError) {
  return {
    type: ActionTypes.UPDATE_CALCULARE_ORDER_ERROR,
    payload: { isCalculateOrderError },
  }
}

/**
 * 清除目前的訂單
 * @returns {ThunkFunction}
 */
export function resetOrder (reason) {
  return (dispatch, getState) => {
    logger.log(`[resetOrder] reason: ${reason}`, { reason })
    // 清除 localStorage orderId
    dispatch(actions.app.resetOrderId())
    // 重設 orderBatch state 並清除 localStorage batch
    dispatch(actions.orderBatch.resetBatch())
    // 關閉全部的 dialog, drawer
    dispatch(actions.app.resetDialogs())
    dispatch(actions.app.resetDrawers())
    // 重設 datetime 為即時
    dispatch(actions.app.updateDatetime({
      date: moment().format('YYYY-MM-DD'),
      time: moment().format('HH:mm'), //
      isImmediate: true,
    }))
    // 清除暫存的 PromoCodes
    dispatch(actions.user.resetPromoCodes())

    // 重設使用 ricecoin
    dispatch(actions.order.resetRiceCoinDiscount())

    // 建立新的 order
    dispatch(actions.order.createLocalOrder())
  }
}

/**
 * 因為 applyCoupon, applyPromoCode, updatePromoCode 都要處理一樣的 error message，因此集中在這裡處理避免重複 code
 * @param {string} errorMessage from response
 * @param {boolean} isCoupon 優惠券和優惠碼的用字不同，根據 isCoupon 會使用不同的 i18n key
 * @returns {ThunkFunction}
 */
export function handleUpdatePromoCodeAlert (errorMessage, isCoupon) {
  return (dispatch) => {
    const title = i18n.t(`app.component.dialog.promo_dialog.alert.${isCoupon ? 'coupon_title' : 'title'}`)
    const messageKeyPredix = `app.component.dialog.promo_dialog.alert.${isCoupon ? 'coupon_message' : 'message'}`

    switch (errorMessage) {
      case 'promocode_amount_is_greater_than_total_price':
        // 未達低消
        dispatch(actions.app.toggleAlert({
          title,
          message: i18n.t(messageKeyPredix + '.order_amount'),
        }))
        break
      case 'promocode_tag_is_not_in_order':
        // tag 不符合
        dispatch(actions.app.toggleAlert({
          title,
          message: i18n.t(messageKeyPredix + '.not_applicable'),
        }))
        break
      case 'promocode_delivery_type_not_match':
        // 運送方式不適用
        dispatch(actions.app.toggleAlert({
          title,
          message: i18n.t(messageKeyPredix + '.delivery_type'),
        }))
        break
      case 'promocode_is_not_open_now':
        // 尚未開放
        dispatch(actions.app.toggleAlert({
          title,
          message: i18n.t(messageKeyPredix + '.not_open'),
        }))
        break

      // 以下都只顯示標題優惠券無效或兌換碼無效
      case 'promocode_is_not_valid':
      case 'promocode valid failed':
      default:
        dispatch(actions.app.toggleAlert({
          title,
        }))
        break
    }
  }
}

/**
 * 根據即時或預約顯示 pickupAt 相關的錯誤訊息
 * @returns {ThunkFunction}
 */
export function showInvalidPickupAtAlert (suffix = '') {
  return (dispatch, getState) => {
    const isImmediate = Boolean(getState().app.params?.datetime?.isImmediate)
    if (isImmediate) {
      // 即時，顯示目前未營業
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.alert.invalid_pickup_at.title'),
        message: i18n.t('app.component.alert.invalid_pickup_at.message') + suffix,
      }))
    } else {
      // 預約，顯示選擇的預約時間不可用
      dispatch(actions.app.toggleAlert({
        title: i18n.t('app.component.alert.invalid_pickup_at_immediate.title'),
        message: i18n.t('app.component.alert.invalid_pickup_at_immediate.message') + suffix,
      }))
    }
  }
}

/**
 *
 * @param {IPromoCodes} promoCodes 依照最優惠的結果排序
 * @returns
 */
export function updatePrioritizedPromoCodes (promoCodes) {
  return {
    type: ActionTypes.UPDATE_PRIORITIZED_PROMO_CODE,
    payload: { promoCodes },
  }
}

/**
 * 領回訂單的 CRM 積分
 * @param {string} orderId
 * @param {string} password
 * @returns {ThunkFunction}
 */
export function claimCRMPoints (orderId, password) {
  return async (dispatch) => {
    try {
      // request 領回積分
      let isSuccess = false
      await dimorderApi.order.claimCRMPoints(orderId, password)
      isSuccess = true
      // 清除 params 和 url 中的 p 和 orderId
      dispatch(actions.app.removeClaimCRMPointsPassword())
      // 顯示領取成功
      dispatch(actions.app.toggleAlert({
        message: i18n.t('app.component.alert.crm_points_claim.success.message'),
      }))
      return isSuccess
    } catch (error) {
      // 顯示領取失敗
      handleAxiosError(error, {
        responseErrorMessagePath: 'response.data.error',
        loggerPrefix: '[claimCRMPoints]',
        loggerExtraData: { orderId, password },
        responseErrorHandler: (errorMessage) => {
          // 清除 params 和 url 中的 p 和 orderId
          dispatch(actions.app.removeClaimCRMPointsPassword())
          const defaultTitleKey = 'app.component.alert.crm_points_claim.wrong password.title'
          const defaultMessageKey = 'app.component.alert.crm_points_claim.wrong password.message'
          const titleKey = `app.component.alert.crm_points_claim.${errorMessage}.title`
          const messageKey = `app.component.alert.crm_points_claim.${errorMessage}.message`

          const title = i18n.exists(titleKey) ? i18n.t(titleKey) : i18n.t(defaultTitleKey)
          const message = i18n.exists(messageKey) ? i18n.t(messageKey) : i18n.t(defaultMessageKey)
          dispatch(actions.app.toggleAlert({
            title,
            message,
          }))
        },
      })
    }
  }
}
